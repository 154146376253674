<template lang="pug">
    video-player(class="video-player-box" ref="videoPlayer" :options="playerOptions" :playsinline="true")
</template>

<script>
  import 'video.js/dist/video-js.css'
  import { videoPlayer } from 'vue-video-player'

  export default {
    components: {
      videoPlayer,
    },

    props: ['video'],

    computed: {
      playerOptions () {
        return {
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0, 2.5, 3.0],
          sources: [{
            type: this.video.type,
            src: this.video.url,
          }],
          poster: this.video.thumbnail,
          fluid: true,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
